import { Box, styled } from '@mui/material'
import { Days } from '../types/days'
import { FC } from 'react'
import { HoursType } from './StoreHours.types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { formatTime } from '../../../utils/time'
import { theme } from '@dtx-company/shared-components/src/'
import { weekdays } from './constants'

const _DaysTable = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 8px; // For chevron indicating collapsed / expended state
`

const _DayHoursRowWrapper = styled(Box)<{ dayIsToday: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  filter: ${({ dayIsToday }) =>
    dayIsToday ? 'brightness(1.3)' : 'none'}; // Help highlight the color if day is today
  margin: 2px 0px;
`

export const HoursDisplay: FC<{
  isClosedNow: boolean
  hours: { [key in Days]: HoursType }
  abbrevTimeZone: string
  defaultColor: string
  todaysDay: string
}> = ({ isClosedNow, hours, abbrevTimeZone, todaysDay, defaultColor }) => {
  return (
    <_DaysTable>
      {weekdays.map((day: Days, idx) => {
        const dayHours = hours[day]
        const dayIsToday = day === todaysDay
        const formattedTime = `${formatTime(dayHours.open)} - ${formatTime(dayHours.close)} ${
          abbrevTimeZone.length && `(${abbrevTimeZone})`
        }`

        let color = defaultColor
        if (dayIsToday) color = theme.colors.masterBuilder.green30
        if (dayIsToday && isClosedNow) color = theme.colors.status.errorDark

        return (
          <_DayHoursRowWrapper dayIsToday={dayIsToday} key={`${day}-${idx}`}>
            <Text variant="body/medium" fontWeight={600} color={color}>
              {capitalize(day)}
            </Text>
            <Text variant="body/medium" fontWeight={600} color={color}>
              {dayHours.active ? formattedTime : 'Closed'}
            </Text>
          </_DayHoursRowWrapper>
        )
      })}
    </_DaysTable>
  )
}
