import * as icons from '../../assets/icons'
import { ActionDataWidgets } from './types'
import {
  AllHoursProps,
  FormFields,
  HoursProps,
  HoursType,
  StoreHoursType,
  TIME_ZERO
} from './StoreHours/StoreHours.types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Card } from '@dtx-company/shared-components/src/components/atoms/Card/index'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { Days } from './types/days'
import { DefaultManager, ManagerProps } from './components/Manager/Manager'
import { ExpandableCard } from './components/Flowpage'
import { FlowpageProps } from './types/FlowpageProps'
import { FormProps, StyledForm } from './components/Layout'
import { FormTitle } from './components/Title'
import { HoursDisplay } from './StoreHours/HoursDisplay'
import { LinkStyleGated } from './components/LinkStyle/LinkStyleGated'
import { SettingsAndSaveLink } from './components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { StoreHours } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/StoreHours'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { TimeInput, TimezonePicker } from './components/Inputs'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { defaultWidgetLink } from '../../constants'
import { formatTime, getCurrentDayAbbrev, getTimezoneAbbrev } from '../../utils/time'
import { getLinkCardStyles } from '../flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { getValidatedActionData, getValidatedActionDataOrThrow } from './typeUtils'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { isClosed } from './StoreHours/StoreHours.hooks'
import { tzOptionsWithoutOffset } from '@app/common/src/constants/timezones'
import { useFlowpageAnalyticsCollector } from '../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../profile/Customize/hooks'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from './components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview } from './utils'
import { useSubmitWidget } from './submitUtils'
import { v4 as uuid } from 'uuid'
import { weekdays } from './StoreHours/constants'
import styled from 'styled-components'

const HoursRow = styled.div`
  display: grid;
  height: 44px;
  align-items: center;
  grid-template-columns: 100px 120px 20px 120px;
  @media (max-width: 1024px) {
    grid-template-columns: 92px 115px 12px 115px;
  }
  grid-template-rows: auto;
  margin-bottom: 10px;
`
const defaultHours = { open: TIME_ZERO, close: TIME_ZERO, active: true }

const defaultStoreHours = weekdays.reduce(
  (acc, day) => ({
    ...acc,
    [day]: {
      ...defaultHours
    }
  }),
  {}
) as StoreHoursType
const Hours: FC<HoursProps> = ({
  key,
  onChange,
  label,
  values,
  disabled = false,
  initialValues,
  day,
  onCheck
}: HoursProps) => {
  return (
    <HoursRow>
      <CheckBox
        onChange={() => onCheck(day)}
        checked={!disabled}
        label={label}
        labelProps={{ textTransform: 'uppercase' }}
      />
      {!disabled && (
        <>
          <TimeInput
            disabled={disabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              e.preventDefault()
              onChange(e.target.value, 'open', day)
            }}
            value={values?.open}
            key={key}
            defaultValue={initialValues?.open || TIME_ZERO}
          />
          <Text textAlign="center" fontSize={20}>
            -
          </Text>
          <TimeInput
            disabled={disabled}
            key={key}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              e.preventDefault()
              onChange(e.target.value, 'close', day)
            }}
            value={values?.close}
            defaultValue={initialValues?.close || TIME_ZERO}
          />
        </>
      )}
    </HoursRow>
  )
}

const AllHours: FC<AllHoursProps> = ({ onChange, values, initialValues }: AllHoursProps) => {
  return (
    <Box width="100%" justifyContent="space-between" alignItems="center">
      <TimeInput
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault()
          e.stopPropagation()
          onChange(e.target.value, 'open')
        }}
        value={values?.open}
        label={'Open from'}
        defaultValue={initialValues?.open || TIME_ZERO}
      />
      <Text textAlign="center" mt="20px" padding="0px 8px" fontSize={20}>
        -
      </Text>
      <TimeInput
        label={'Open till'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.preventDefault()
          e.stopPropagation()
          onChange(e.target.value, 'close')
        }}
        value={values?.close}
        defaultValue={initialValues?.close || TIME_ZERO}
      />
    </Box>
  )
}

export const Form: FC<FormProps> = ({ widgetObj, order, curr, handleClose }: FormProps) => {
  const id = curr ? curr.id : uuid()

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const [showTimezonePicker, setShowTimezonePicker] = useState(false)
  const actionData = getValidatedActionData<'storeHours'>(curr?.actionData, 'hours')
  const timeZone = curr ? actionData?.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
  const abbrevTimeZone = useMemo(() => getTimezoneAbbrev(timeZone), [timeZone])
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      hours: curr ? actionData?.hours : defaultStoreHours,
      isSameHours: curr ? actionData?.isSameHours : true,
      timeZone: { label: abbrevTimeZone, value: timeZone }
    }
  })

  const submitWidget = useSubmitWidget()
  const onSubmit = async ({
    hours,
    isSameHours,
    timeZone
  }: {
    hours: StoreHoursType
    isSameHours: boolean
    timeZone: { label: string; value: string }
  }): Promise<void> => {
    const actionData = { hours, isSameHours, timeZone: timeZone.value }

    await submitWidget({
      curr,
      actionData,
      handleClose,
      linkTheme,
      widgetType: (curr ? curr.type : widgetObj.type) as ActionDataWidgets,
      fields: {
        title: '',
        description: '',
        id,
        order
      }
    })
  }

  const watchAll = watch()
  const hours: StoreHoursType = watchAll?.hours || defaultStoreHours

  const previewLink = useMemo(
    () => ({
      ...defaultWidgetLink,
      linkTheme,
      type: 'storeHours',
      id,
      actionData: {
        timeZone: watchAll.timeZone.value,
        isSameHours: Boolean(watchAll.isSameHours),
        hours
      }
    }),
    [hours, id, linkTheme, watchAll.isSameHours, watchAll.timeZone.value]
  )
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )

  const handleHoursChange = (time: string, type: 'open' | 'close', day: Days): void => {
    const currDay: HoursType = indexInterfaceWithVar(hours, day) || defaultHours

    const newHours = {
      ...currDay,
      [type]: time
    }
    setValue(FormFields.HOURS, { ...hours, [day]: newHours })
  }
  const handleAllHoursChange = useCallback(
    (time: string, type: 'open' | 'close'): void => {
      const newHours = Object.keys(hours).reduce(
        (week, day) => ({
          ...week,
          [day]: {
            ...hours[day as Days],
            [type]: time,
            active: true
          }
        }),
        {}
      )
      setValue(FormFields.HOURS, newHours)
    },
    [hours, setValue]
  )

  const toggleSameHours = useCallback((): void => {
    if (!watchAll.isSameHours) {
      const newHours = Object.keys(hours).reduce(
        (week, day) => ({
          ...week,
          [day]: {
            open: hours.mon.open,
            close: hours.mon.close,
            active: true
          }
        }),
        {}
      )
      setValue(FormFields.HOURS, newHours)
    }

    setValue(FormFields.SAME_HOURS, !watchAll.isSameHours)
  }, [hours, setValue, watchAll.isSameHours])

  const toggleActiveDay = useCallback(
    (day: Days): void => {
      const currDay: HoursType = indexInterfaceWithVar(hours, day) || defaultHours

      setValue(FormFields.HOURS, {
        ...hours,
        [day]: {
          ...currDay,
          active: !currDay?.active
        }
      })
    },
    [hours, setValue]
  )
  useEffect(() => {
    register(FormFields.HOURS)
    register(FormFields.SAME_HOURS)
    register(FormFields.TIMEZONE)
  }, [register])
  const edit = Boolean(curr)
  return (
    <>
      <StyledForm
        disableAutoSpace
        onSubmit={handleSubmit(onSubmit)}
        title={
          <FormTitle icon={<StoreHours />} title={`${edit ? 'Edit these' : 'Add'} store hours`} />
        }
      >
        <Box mb="8px" width="100%" justifyContent="space-between">
          <Box flexDirection="column">
            <Text>Timezone: {watchAll.timeZone.label}</Text>
            <Text
              cursor="pointer"
              fontSize="13px"
              variant="body/small"
              fontWeight="bold"
              onClick={() => setShowTimezonePicker(!showTimezonePicker)}
            >
              {showTimezonePicker ? 'Hide' : 'Change Timezone'}
            </Text>
          </Box>
          {showTimezonePicker && (
            <TimezonePicker
              onSelect={val => {
                setValue(FormFields.TIMEZONE, val, { shouldDirty: true })
              }}
            />
          )}
        </Box>
        <Toggle
          checked={Boolean(watchAll.isSameHours)}
          onChange={toggleSameHours}
          label={'Use same hours for all days'}
          mb="24px"
        />
        {watchAll.isSameHours ? (
          <AllHours
            values={hours.mon || defaultHours}
            initialValues={curr ? (actionData?.hours.mon as HoursType) : null}
            onChange={handleAllHoursChange}
          />
        ) : (
          weekdays.map((day, idx) => (
            <Hours
              label={day}
              values={indexInterfaceWithVar(hours, day) || defaultHours}
              day={day}
              initialValues={curr ? (actionData?.hours[day] as HoursType) : null}
              key={idx}
              disabled={!hours[day]?.active}
              onCheck={toggleActiveDay}
              onChange={handleHoursChange}
            />
          ))
        )}

        <Spacer mb="16px" />
        <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkThemeValue} />

        <Spacer mb="16px" />
        <SettingsAndSaveLink disabled={false} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview = false,
  editLinkMode = false,
  isLockedTemplateLink
}: FlowpageProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [clicked, setClicked] = useState<boolean>(false)
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: preview,
    isEditMode: editLinkMode
  })
  const { theme } = useFlowpageTheme()

  const linkStyles = getLinkCardStyles({
    theme,
    linkTheme: link.linkTheme,
    isFeaturedLink: false
  })
  const toggleExpanded = (): void => {
    setExpanded(!expanded)
    if (!clicked) {
      trackLinkClick({ link_id: link.id }, { useHeap: true })
      setClicked(true)
    }
  }
  const actionData = getValidatedActionDataOrThrow<'storeHours'>(link.actionData, 'hours')
  const { hours, timeZone } = actionData
  const abbrevTimeZone = tzOptionsWithoutOffset.find(zone => zone.value === timeZone)?.label ?? ''
  const curDay = getCurrentDayAbbrev()
  const date = new Date()
  const d = new Date(
    date.toLocaleString('en-US', {
      timeZone: timeZone || 'America/New_York'
    })
  )
  const nowHours = d.getHours()
  const nowMins = d.getMinutes()
  const curDayHours = indexInterfaceWithVar(hours, curDay)
  const isClosedNow = isClosed(nowHours, nowMins, curDayHours || null)
  const defaultColor = link?.linkTheme?.active ? linkStyles.color : 'primary.black'

  return actionData?.empty ? (
    <Card pt="16px">
      <Text textAlign="center" variant="body/small" fontWeight={600} color="primary.black" mb="8px">
        Show what time your business is open
      </Text>
      <HoursDisplay
        todaysDay={curDay}
        isClosedNow={isClosedNow}
        defaultColor={defaultColor}
        abbrevTimeZone={abbrevTimeZone}
        hours={hours}
      />
    </Card>
  ) : (
    <ExpandableCard
      preview={preview}
      dataTestId="expandable-hours-card"
      expanded={expanded || Boolean(actionData.empty)}
      toggleExpanded={toggleExpanded}
      compactView={true}
      id={link.id}
      {...linkStyles}
      isLockedTemplateLink={isLockedTemplateLink}
      editLinkMode={editLinkMode}
      title={
        <>
          <Text fontWeight={600} fontSize="inherit" color="inherit" lineHeight="inherit">
            {isClosedNow ? 'Closed Now' : 'Open Now'}
          </Text>
          {curDayHours?.active && (
            <>
              <Text fontSize="inherit" fontWeight={600} color="inherit" lineHeight="inherit">
                {'\u2022'}
              </Text>
              <Text fontSize="inherit" lineHeight="inherit" color="inherit">
                {formatTime(curDayHours.open)} - {formatTime(curDayHours.close)}
              </Text>
            </>
          )}
        </>
      }
    >
      <HoursDisplay
        todaysDay={curDay}
        isClosedNow={isClosedNow}
        defaultColor={defaultColor}
        abbrevTimeZone={abbrevTimeZone}
        hours={hours}
      />
    </ExpandableCard>
  )
}

export const Manager: FC<ManagerProps> = ({ editWidget, link, handle }: ManagerProps) => {
  return (
    <DefaultManager
      iconUrl={icons.clock}
      altTitle="Store Hours"
      handle={handle}
      editWidget={editWidget}
      link={link}
    />
  )
}
