import { HoursType } from './StoreHours.types'

export const isClosed = (curHour: number, curMin: number, hours: HoursType | null): boolean => {
  if (!hours || !hours.active) {
    return true
  }
  const [openHours, openMinutes] = hours.open.split(':')
  const [closeHours, closeMinutes] = hours.close.split(':')

  const isMinutesAfterClosingTime = curHour === Number(closeHours) && curMin > Number(closeMinutes)
  const isMinutesBeforeOpeningTime = curHour === Number(openHours) && curMin < Number(openMinutes)
  if (
    Number(openHours) > Number(closeHours) ||
    (Number(openHours) === Number(closeHours) && Number(openMinutes) > Number(closeMinutes))
  ) {
    //opening hours roll over midnight
    if (curHour > Number(closeHours) && curHour < Number(openHours)) return true
    if (isMinutesAfterClosingTime || isMinutesBeforeOpeningTime) return true
  } else {
    if (curHour > Number(closeHours) || curHour < Number(openHours)) return true
    if (isMinutesAfterClosingTime || isMinutesBeforeOpeningTime) return true
  }
  return false
}
