import { Days } from '../types/days'

export enum FormFields {
  TITLE = 'title',
  HOURS = 'hours',
  SAME_HOURS = 'isSameHours',
  TIMEZONE = 'timeZone'
}
export const TIME_ZERO = '12:00'

export type HoursType = {
  open: string
  close: string
  active: boolean
}

export type StoreHoursType = {
  [key in Days]: {
    open: string
    close: string
    active: boolean
  }
}
export interface HoursProps {
  key: string | number
  onChange: (time: string, type: 'open' | 'close', day: Days) => void
  onCheck: (day: Days) => void
  disabled?: boolean
  values: HoursType
  label: string
  day: Days
  initialValues: HoursType | null
}

export interface AllHoursProps {
  onChange: (time: string, type: 'open' | 'close') => void
  disabled?: boolean
  values: HoursType
  initialValues: HoursType | null
}
