import { Days } from '../components/widgets/types/days'

const DAYS: Days[] = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat']
export const getCurrentDayAbbrev = (): Days => {
  const date = new Date()
  return DAYS[date.getDay()]
}

export const formatTime = (time: string): string => {
  const [hours, minutes] = time.split(':')
  let hoursNum = Number(hours)
  let type = 'AM'
  if (hoursNum === 0) {
    return `12:${minutes} AM`
  }
  if (Number(hoursNum) > 12) {
    hoursNum -= 12
    type = 'PM'
  }
  if (Number(hoursNum) === 12) {
    type = 'PM'
  }
  return `${hoursNum}:${minutes} ${type}`
}

export function getTimezoneAbbrev(timeZone?: string): string {
  if (!timeZone) {
    return ''
  }
  return Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short'
  })
    .format()
    .split(', ')[1]
}
