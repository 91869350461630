import * as icons from '../../../assets/icons'
import { AnimatedImage } from './Manager/Manager'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { EditLinkCardEndAdornment } from '../EditLinkUtils'
import { FC, ReactNode } from 'react'

export interface ExpandedProps {
  title: ReactNode
  expanded: boolean
  toggleExpanded(): void
  compactView?: boolean
  preview?: boolean
  children?: ReactNode
  id: string
  editLinkMode?: boolean
  dataTestId?: string
  isLockedTemplateLink?: boolean
}
export const ExpandableCard: FC<ExpandedProps> = ({
  title,
  expanded,
  toggleExpanded,
  compactView = false,
  children,
  id,
  editLinkMode = false,
  dataTestId,
  isLockedTemplateLink,
  ...rest
}: ExpandedProps) => {
  return (
    <Box
      boxShadow={'float'}
      data-testid={dataTestId}
      borderRadius={3}
      id={id}
      width="100%"
      onClick={!editLinkMode && toggleExpanded}
      flexDirection="column"
      {...rest}
    >
      <Box
        padding={expanded ? '16px 12px' : '34px 12px'}
        width="100%"
        justifyContent={title ? 'space-between' : 'flex-end'}
        alignItems={'center'}
      >
        {compactView ? !expanded && title : title}
        {expanded && children}
        {!editLinkMode && (
          <AnimatedImage width="12px" isOpen={expanded} src={icons.arrowDownBlack} />
        )}
        {editLinkMode && (
          <EditLinkCardEndAdornment
            padding={'0 0 0 10px'}
            margin={'0 0 0 auto'}
            iconWidth={'10px'}
            iconMargin="auto"
            isLockedTemplateLink={isLockedTemplateLink}
          />
        )}
      </Box>
    </Box>
  )
}
